import { Alert, Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from 'store/slices/NotificationSlice';

// eslint-disable-next-line react/prop-types
const Notifications = () => {
  const { notifications } = useSelector((state) => state.notifications) || {};
  const dispatch = useDispatch();

  // todo : useEffect being called multiple times even after notifications are removed

  useEffect(() => {
    if (notifications.length > 0) {
      notifications.forEach((notification) => {
        const timeoutId = setTimeout(() => {
          dispatch(removeNotification(notification.id));
        }, notification.delay);
        return () => clearTimeout(timeoutId);
      });
    }
  }, [notifications, dispatch]);

  return (
    <Box position={'fixed'} top={10} right={10} zIndex={9999}>
      {notifications.map((notification, index) => {
        if (notification.type === 'async') {
          return (
            <Alert
              icon={<CircularProgress size={20} />}
              sx={{ margin: '10px', transition: '0.5s', minWidth: '200px' }}
              key={index}
              severity={'info'}
              onClose={() => dispatch(removeNotification(notification.id))}
            >
              {notification.message || 'info'}
            </Alert>
          );
        } else {
          return (
            <Alert
              sx={{ margin: '10px', transition: '0.5s', minWidth: '200px' }}
              key={index}
              severity={notification.type || 'info'}
              onClose={() => dispatch(removeNotification(notification.id))}
            >
              {notification.message || 'info'}
            </Alert>
          );
        }
      })}
    </Box>
  );
};

export default Notifications;
